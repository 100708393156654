import { Component } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ApiserviceService } from '../../../../src/app/apiservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../src/environments/environment';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-admin-visibility-calculation',
  templateUrl: './admin-visibility-calculation.component.html',
  styleUrls: ['./admin-visibility-calculation.component.scss']
})
export class AdminVisibilityCalculationComponent {
  propertyList: any;
  visibilityFormGroup:FormGroup;
  loading = false;
  constructor(
    private menus: AdminMenusService,
    private api: ApiserviceService,
    private fb:FormBuilder,
    private notify: NotificationService,
  ) {
    this.menus.setActive("visibility-calculation");
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.visibilityFormGroup = this.fb.group({
      propertyDetailsControl:['', Validators.required]
    })
    this.getPropertyDetails()
  }
  selectionChange(){

  }

  getPropertyDetails(){
    let url = `calculation-properties?layer=unit&limit=1000`;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        this.propertyList = res.data;
        console.log(this.propertyList);

      },
    error:(err)=>{}})
  }

  calculate(){
    let url = "calculate-visibility";
    let body = {
      property_id: this.visibilityFormGroup.value.propertyDetailsControl,
      "floor_id": 0,
      "observer_ids": [],
      "radius": 0
    }
    this.loading = true;
    this.api.postEpsData(url, body).subscribe(
      {next:(res)=>{
        console.log(res);
        this.loading = true;
      },
      error:(err)=>{
        this.loading = true;
      }})
  }
  downloadFile(){
    let url = `calculation-download?&property_id=${this.visibilityFormGroup.value.propertyDetailsControl}`;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        console.log(res);
        let exportFileUrl = `${environment.epsUrl}visibility-download-file?city_code=${this.api.city_code.toLowerCase()}&file_name=${res.data}`
          window.open(exportFileUrl, "_blank")
      },
     error:(err:any)=>{}})
  }
}
